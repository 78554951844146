import { createContext, useContext, useState } from "react";
import { OrcamentoVendaContext } from "./orcamentovenda-context";
import { Formik } from "formik";
import * as Yup from "yup";
import T from "../common/traducao";
import { Form } from "react-bootstrap";
import OrcamentoVendaService from "../services/service.orcamentovenda";


export const ItemOrcamentoVendaContext = createContext({
    itemorcamentoVenda: {},
    itensFiname: {}
});

const ItemOrcamentoVendaContextProvider = ({ children }) => {

    const {
        configs,
        orcamentoVenda,
        indexselectedItem,
        selectedItem,
        cfop,
        maioritem,
        onEditItem,
        onAddItem
    } = useContext(OrcamentoVendaContext)

    const itensFiname = orcamentoVenda.ItensFiname?.map((item, index) => {
        return { Id: index, Valor: item.NomeProduto }
    })

    const _initialvalues = { ItemOrcamentoVenda: selectedItem };

    const initialValues = {};

    const itemorcamentoVenda = _initialvalues;


    const tabelaPrecoId = orcamentoVenda?.TabelaPreco?.Id;

    // initialValues.ItemOrcamentoVenda.TabelaPrecoId = tabelaPrecoId;
    // initialValues.ItemOrcamentoVenda.Cfop = cfop;
    // initialValues.ItemOrcamentoVenda.Item = maioritem ? maioritem + 1 : 1;

    const [modalComposicao, setModalComposicao] = useState(false);
    const [modalAtributos, setModalAtributos] = useState(false);

    const [multiplos, setMultiplos] = useState(_initialvalues?.ItemOrcamentoVenda?.QtdeMultiplaVenda);
    //const [quantidadeAtual, setQuantidadeAtual] = useState(0);
    const [devePreencherAtributos, setDevePreencherAtributos] = useState(configs?.UsarAtributosVenda && (_initialvalues?.ItemOrcamentoVenda?.FormulaParaQuantidadeId > 0));
    const [deveMostrarSobMedida, setDeveMostrarSobMedida] = useState(_initialvalues?.ItemOrcamentoVenda?.PermitirSobMedida);
    const [permiteFracionario, setPermiteFracionario] = useState(_initialvalues?.ItemOrcamentoVenda?.TipoUnidadeMedida == 'Fracionada');

    const [atributos, setAtributos] = useState(_initialvalues?.ItemOrcamentoVenda?.Atributos);

    const [unidadeMedida, setUnidadeMedida] = useState(_initialvalues?.ItemOrcamentoVenda?.UnidadeMedidaComercial);
    const [precoTabela, setPrecoTabela] = useState(_initialvalues?.ItemOrcamentoVenda?.PrecoTabela);
    const [precoTabelaComDesconto, setPrecoTabelaComDesconto] = useState(_initialvalues?.ItemOrcamentoVenda?.PrecoTabelaComDesconto);
    const [precoUnitario, setPrecoUnitario] = useState(_initialvalues?.ItemOrcamentoVenda?.PrecoUnitario);
    const [totalItem, setTotalItem] = useState(_initialvalues?.ItemOrcamentoVenda?.TotalItem);
    const [calcular, setCalcular] = useState(false);


    const [loadErro, setLoadErro] = useState(false);
    const [erros, setErros] = useState(null);
    const [loadingDesconto, setLoadingDesconto] = useState(false);
    const [loadingGlobal, setLoadingGlobal] = useState(false)
    const constantes = T();


    function isMultiploAproximado(divisor, dividendo) {
        const remainder = divisor % dividendo;
        const tolerance = 0.00001; // Margem de erro aceitável
        return Math.abs(remainder) < tolerance || Math.abs(remainder - dividendo) < tolerance;
        }

    const SchemaItemOrcamento = {
        ItemOrcamentoVenda: Yup.object({
            CodProduto: Yup.string().required("Deve ser preenchido"),
            Quantidade: Yup.string().required("Deve ser preenchido"),
            PrecoUnitario: Yup.number().moreThan(0, " deve ser > 0").required("Deve ser preenchido"),
        })
    }

    function SetDadosProdutoDeItem(item) {
        setUnidadeMedida(item?.UnidadeMedidaComercial);
        setPrecoTabela(item?.PrecoTabela);
        setPrecoTabelaComDesconto(item?.PrecoTabelaComDesconto);
        setPrecoUnitario(item?.PrecoUnitario);
        setTotalItem(item?.TotalItem);
        setPermiteFracionario(item?.TipoUnidadeMedida == 'Fracionada')
        setMultiplos(item?.QtdeMultiplaVenda);
        setDevePreencherAtributos(configs?.UsarAtributosVenda && item?.FormulaParaQuantidadeId > 0);
        setDeveMostrarSobMedida(item?.PermitirSobMedida);
    }

    function onLoad(loading) {
        //setLoad(loading);
    }

    function onError(error) {

        const _erro = error?.response
            ? error?.response.data.error.message
            : error.message;

        setErros(_erro);
        setLoadErro(true);
    }

    function onToogleErro() {
        setLoadErro(prevvalue => !prevvalue);
        setErros(null);
    }

    function AtualizarCalculo(setFieldValue, values, resetForm, codproduto) {

        if ((orcamentoVenda) && (codproduto)) {
            onLoad && onLoad(true)

            const _orcamentovenda = JSON.parse(JSON.stringify(orcamentoVenda));

            const _itemorcamentovenda = JSON.parse(JSON.stringify(values.ItemOrcamentoVenda));
            _itemorcamentovenda.CodProduto = codproduto;

            let indexitem = -1;
            if ((selectedItem) && (indexselectedItem != null)) {
                _orcamentovenda.Itens[indexselectedItem] = _itemorcamentovenda;
                indexitem = indexselectedItem;
            } else {
                _orcamentovenda.Itens = _orcamentovenda.Itens.concat(_itemorcamentovenda);
                indexitem = orcamentoVenda.Itens.length;
            }

            OrcamentoVendaService.Calcular(_orcamentovenda)
                .then(response => {
                    const itemorcamentovenda = response.data.Itens[indexitem];

                    if (itemorcamentovenda.Quantidade === 0) {
                        itemorcamentovenda.Quantidade = null;
                        itemorcamentovenda.TotalItem = 0;
                    }

                    setFieldValue('ItemOrcamentoVenda', itemorcamentovenda);
                    SetDadosProdutoDeItem(itemorcamentovenda);

                    if (itemorcamentovenda.Quantidade === 0) {
                        setTotalItem(0);
                    }
                    setLoadingDesconto(false)
                    setLoadingGlobal(false)
                    //     setAtributos(itemorcamentovenda.Atributos);
                }
                )
                .catch(error => {
                    const _error = error?.response?.data ? error.response.data.error : error;
                    onError && onError(_error);
                    resetForm();
                })
                .finally(() => {

                    onLoad && onLoad(false);
                    setCalcular(false);

                });
        }
    }

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={selectedItem ? _initialvalues : initialValues}

                //TotalItem: Yup.number().moreThan(0, " deve ser > 0").required("Deve ser preenchido"),//

                validationSchema={Yup.object().shape(SchemaItemOrcamento)}


                validate={(values, isValidating) => {
                    let errors = {};

                    if (multiplos && multiplos > 0) {
                        if (!isMultiploAproximado(values.ItemOrcamentoVenda?.Quantidade, multiplos)) {
                            return {
                                ItemOrcamentoVenda: {
                                    Quantidade: `A Quantidade deve ser múltipla de ${multiplos}`
                                }
                            }
                        }
                    }

                    if (!permiteFracionario) {
                        const valorfracionado = values.ItemOrcamentoVenda?.Quantidade % 1 !== 0;

                        if (valorfracionado) {
                            return {
                                ItemOrcamentoVenda: {
                                    Quantidade: `Valor fracionado não é permitido para o item`
                                }
                            }
                        }
                    }

                    return errors;
                }}

                onSubmit={(values, { setSubmitting, resetForm, setFieldValue }) => {
                    selectedItem ?
                        onEditItem(values.ItemOrcamentoVenda) :
                        onAddItem(values.ItemOrcamentoVenda);
                    setSubmitting(false);
                    resetForm();
                    SetDadosProdutoDeItem(initialValues);

                }}
            >
                {() => (
                    <>
                        <Form>
                            <ItemOrcamentoVendaContext.Provider value={
                                {
                                    itemorcamentoVenda,
                                    itensFiname,
                                    constantes,

                                    modalComposicao,
                                    setModalComposicao,
                                    modalAtributos,
                                    setModalAtributos,
                                    multiplos,
                                    setMultiplos,
                                    devePreencherAtributos,
                                    setDevePreencherAtributos,
                                    deveMostrarSobMedida,
                                    setDeveMostrarSobMedida,
                                    permiteFracionario,
                                    setPermiteFracionario,
                                    atributos,
                                    setAtributos,

                                    unidadeMedida,
                                    setUnidadeMedida,
                                    precoTabela,
                                    setPrecoTabela,
                                    precoTabelaComDesconto,
                                    setPrecoTabelaComDesconto,
                                    precoUnitario,
                                    setPrecoUnitario,
                                    totalItem,
                                    setTotalItem,
                                    calcular,
                                    setCalcular,

                                    loadErro,
                                    setLoadErro,
                                    erros,
                                    setErros,
                                    loadingDesconto,
                                    setLoadingDesconto,
                                    loadingGlobal,
                                    setLoadingGlobal,

                                    onToogleErro,
                                    onLoad,
                                    onError,
                                    tabelaPrecoId

                                }}>
                                {children}
                            </ItemOrcamentoVendaContext.Provider>
                        </Form>
                    </>
                )}
            </Formik>
        </>
    )
}

export default ItemOrcamentoVendaContextProvider;