import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import SearchComp from "../../common/searchcomp";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NewAlertError from "../../components/ui_comp/Alerts/views.alert.error";
import T from "../../common/traducao";
import {
  GetPermissaoEditar,
  GetPermissaoExcluir,
  GetPermissaoInserir,
  GetPermissaoVisualizar,
  getToken,
} from "../../config/localstorageConfig/localstorage";
import jwt_token from "jwt-decode";
import { Alert } from "react-bootstrap";
import Cookies from "js-cookie";
import { TOKEN_KEY } from "../../config/localstorageConfig/localstorage";
import serviceAssistencias from "../../services/service.assistencias";
import { toast } from "react-toastify";
import AssistenciasList from "./assistencias-list";
import { BsChatRightTextFill } from "react-icons/bs";
import NewImprimirAssistencia from "../../components/ui_comp/Modals/ModalPrintOut/ImprimirAssistenciaControl";
import ModalImprimir from "../../components/ui_comp/Modals/ModalPrintOut";
import ModalDelete from "../../components/ui_comp/Modals/ModalDelete";
import { Typography } from "@mui/material";
import { SituacaoAssistencia, getStyle } from "../../consts/situacao_assistencia";

const Assistencia = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [assistencias, setAssistencias] = useState();
  const [clientesId, setClientesId] = useState(null);
  const [pages, setPages] = useState();
  const [records, setRecords] = useState();
  const [filterstate, setFilterState] = useState();
  const [filterChip, setFilterChip] = useState()
  const [situacaoSelecionada, setSituacaoSelecionada] = useState()
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [modalExcluirAssistencia, setModalExcluirAssistencia] = useState(false);
  const [modalImprimirAssistencia, setModalImprimirAssistencia] = useState(false);
  const [indexDeletarOrcamento, setIndexDeletarOrcamento] = useState()
  const [camposSelecionados, setCamposSelecionados] = useState()
  const token = Cookies.get(TOKEN_KEY);
  const ordertype = useRef();
  const orderField = useRef();

  const [permiteVisualizar, setPermiteVisualizar] = useState(false);
  const [permiteEditar, setPermiteEditar] = useState(false);
  const [permiteExcluir, setPermiteExcluir] = useState(false);
  const [permiteInserir, setPermiteInserir] = useState(false);

  const [IndexAssistenciaSelecionada, setIndexAssistenciaSelecionada] = useState();
  const [IndexDeletarAssistencia, setIndexDeletarAssistencia] = useState();
  const [IndexImprimirAssistencia, setIndexImprimirAssistencia] = useState();
  const [IndexDuplicarAssistencia, setIndexDuplicarAssistencia] = useState();

  const [redirect, setRedirect] = useState();

  const constantes = T();

  const getIdAssistencia = (index) => {
    return assistencias && assistencias[index]?.Id;
  }

  const columns = [
    {
      field: "Id",
      headerName: constantes.NumeroAssistencia,
      width: 100,
      key: true,
    },
    {
      field: "DataCadastro",
      headerName: constantes.DataCadastro,
      width: 90,
      type: "date",
      format: "dd/MM/yyyyy",
    },
    {
      field: "DataSolicitacao",
      headerName: constantes.DataSolicitacao,
      width: 90,
      type: "date",
      format: "dd/MM/yyyyy",
    },
    {
      field: "NomeCliente",
      headerName: constantes.Nome,
      width: 150,
    },
    {
      field: "IdCliente",
      headerName: constantes.CodigoCliente,
      width: 100,
      key: true,
    },
    {
      field: "IdVendedor",
      headerName: constantes.CodigoVendedor,
      width: 150,
    },
    {
      field: "NomeVendedor",
      headerName: constantes.NomeVendedor,
      width: 150,
    },
    {
      field: "IdCausa",
      headerName: constantes.CodigoCausa,
      width: 150,
    },
    {
      field: "Situacao",
      headerName: constantes.Situacao,
      width: 150,
    },
    {
      field: "SolicitarVisitaTecnica",
      headerName: constantes.SolicitarAssistenciaTecnica,
      width: 150,
    }

    /* {
      field: "SituacaoSolucao",
      headerName: "Situação Solução",
      width: 90,
    },
    {
      field: "ObservacaoUsuario",
      headerName: "Observação Usuário",
      width: 150,
    },
    {
      field: "PedidoVendas",
      headerName: "Pedido Vendas",
      width: 150,
    },
    {
      field: "PedidoVendaOrigem",
      headerName: "Pedido Venda Origem",
      width: 150,
    },
    {
      field: "PedidoVendaEnvio",
      headerName: "Pedido Venda Envio",
      width: 150,
    },

    {
      field: "CodigoTipoAssistência",
      headerName: "Código Tipo Assistência",
      align: "left",
    },
    {
      field: "NomeTipoAssistencia",
      headerName: "Nome Tipo Assistência",
      align: "left",
    },
    {
      field: "CodigoConsumidor",
      headerName: "Código Consumidor",
      align: "left",
    },
    {
      field: "CPFCNPJConsumidor",
      headerName: "CPF/CNPJ Consumidor",
      align: "left",
    },
    {
      field: "NumeroAtendimento",
      headerName: "Número Atendimento",
      align: "left",
    },
    {
      field: "NomeCausa",
      headerName: "Nome Causa",
      align: "left",
    },
 */

    /*
    
        {
          field: "CodigoVendas",
          headerName: constantes.CodigoVendas,
          tipo: "text",
        },
        {
          field: "NomeVendedor",
          headerName: constantes.NomeVendedor,
          tipo: "text",
        },
    
    */

  ];

  const columnsFilter = [
    {
      field: "Id",
      headerName: constantes.NumeroAssistencia,
      tipo: "text",
    },

    {
      field: "DataCadastro",
      headerName: constantes.DataCadastro,
      tipo: "date",
      format: 'dd/MM/yyyyy'
    },
    {
      field: "DataSolicitacao",
      headerName: constantes.DataSolicitacao,
      tipo: "date",
      format: 'dd/MM/yyyyy'
    },
    {
      field: "IdCliente",
      headerName: constantes.CodigoCliente,
      tipo: "number",
    },
    {
      field: "NomeCliente",
      headerName: constantes.Nome,
      tipo: "text",
    },
    {
      field: "ObservacaoUsuario",
      headerName: constantes.ObservacaoUsuario,
      tipo: "text",
    },
    {
      field: "IdCausa",
      headerName: constantes.CodigoCausa,
      tipo: "number",
    },
    {
      field: "NomeCausa",
      headerName: constantes.NomeCausa,
      tipo: "text",
    },
    {
      field: "SolicitarVisitaTecnica",
      headerName: constantes.SolicitarAssistenciaTecnica,
      tipo: "checkbox",
    },
  ];

  const navigate = useNavigate();

  useEffect(() => {
    redirect && navigate(`/${redirect}`);
  }, [redirect]);

  useLayoutEffect(() => {
    // try {
    const token_salvo = getToken();
    if (token_salvo) {
      const token = jwt_token(token_salvo);
      let filter = {
        campo: null,
        operacao: null,
        valor: null,
      };

      getAssistencias(filter);

      setPermiteVisualizar(GetPermissaoVisualizar("assistencia"));
      setPermiteEditar(GetPermissaoEditar("assistencia"));
      setPermiteExcluir(GetPermissaoExcluir("assistencia"));
      setPermiteInserir(GetPermissaoInserir("assistencia"));
    } else {
      setRedirect("logout");
    }
  }, []);

  useEffect(() => {
    const id = getIdAssistencia(IndexAssistenciaSelecionada);
    id && navigate(`/assistencias/${id}`);
  }, [IndexAssistenciaSelecionada]);


  useEffect(() => {
    const id = getIdAssistencia(IndexDuplicarAssistencia);
    id && navigate(`/assistencias/duplicar/${id}`);
  }, [IndexDuplicarAssistencia]);


  function getAssistencias(filter) {
    setError(null);
    setLoading(true);
    serviceAssistencias
      .getAll(filter)
      .then((response) => {
        setAssistencias(response.data.value);
        setPages(response.data.pages);
        setRecords(response.data.registros);
        setFilterState(filter);
      })
      .catch((error) => {
        const _error =
          error.code === "ERR_NETWORK"
            ? "Server OffLine"
            : error.response && error.response.data && error.response.data.error
              ? error.response.data.error.message
              : error.message;
        setError(_error);
        {
          error.code === "ERR_NETWORK" && navigate("/logout");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }


  async function deletarAssistencia(id) {

    await serviceAssistencias.deletarAssistenciaService(id)
      .then(() => {
        getAssistencias(filterstate);
        setModalExcluirAssistencia(false)
        toast.success(`Assistencia ${id} deletada com sucesso`)
      })
      .catch((error) => {
        const _error =
          error.code === "ERR_NETWORK"
            ? "Server OffLine"
            : error.response && error.response.data && error.response.data.error
              ? error.response.data.error.message
              : error.message;
        setError(_error);
      })
      .finally(() => { });
  }

  function onSearchTitle(searchValue, searchField, searchFilter, searchFieldTranslate) {
    setCamposSelecionados({
      campo: searchField,
      operacao: searchFilter,
      valor: searchValue,
      campoTraducao: searchFieldTranslate
    })
    setSituacaoSelecionada(null)

    let filter = {
      situacao: null,
      campo: searchField,
      operacao: searchFilter,
      valor: searchValue,
      campoorder: orderField.current,
      tipoorder: ordertype.current,
      filters: filterChip,
    };

    getAssistencias(filter);
  }

  function getSearchView() {
    return permiteVisualizar ? (
      <SearchComp
        hasBotao={permiteInserir}
        to="/assistencias/new"
        botaoDisabilitado={loading}
        title={constantes.Assistencias}
        campos={columnsFilter}
        onSearch={(values) => {
          onSearchTitle(
            values.searchValue,
            values.searchField,
            values.searchoperator,
            values.searchFieldTranslate
          );
        }}
      />
    ) : null;
  }

  function OrderChange(field, type) {
    orderField.current = field;
    ordertype.current = type;

    let filter = {
      ...filterstate,
      campoorder: field,
      tipoorder: type,
    };
    getAssistencias(filter);
  }

  function btnSituacaoOnClick(event) {
    setSituacaoSelecionada(event.target.attributes.situacao.value)
    let filter = {
      situacao: event.target.attributes.situacao.value,
      campo: camposSelecionados?.campo ? camposSelecionados?.campo : null,
      operacao: camposSelecionados?.operacao ? camposSelecionados?.operacao : null,
      valor: camposSelecionados?.valor ? camposSelecionados?.valor : null,
      campoorder: orderField.current,
      tipoorder: ordertype.current,
      filters: filterChip
    };

    getAssistencias(filter);
  }

  function getFilterSituacao() {
    return (
      <Container style={{ margin: "20px 0", }}>
        <Row className="d-flex justify-content-between "  >
          <Col xs={12} md={6} lg={6} xl={2} xxl={1}  >
            <Button
              style={getStyle(SituacaoAssistencia.RASCUNHO)}
              variant="primary"
              disabled={loading}
              onClick={btnSituacaoOnClick}
              situacao="Rascunho"
              className="w-100"
            >
              {constantes.Rascunho}
            </Button>
          </Col>
          <Col xs={12} md={6} lg={6} xl={2} xxl={1}  >
            <Button

              style={getStyle(SituacaoAssistencia.NOVA)}
              disabled={loading}
              onClick={btnSituacaoOnClick}
              situacao="Nova"
              className="w-100"
            >
              {constantes.Novas}
            </Button>
          </Col>
          <Col xs={12} md={6} lg={6} xl={2} xxl={1} >
            <Button

              style={getStyle(SituacaoAssistencia.EM_ANALISE)}
              disabled={loading}
              onClick={btnSituacaoOnClick}
              situacao="Em Análise"
              className="w-100"
            >
              {constantes.EmAnalise}
            </Button>
          </Col>
          <Col xs={12} md={6} lg={6} xl={2} xxl={1} >
            <Button
              style={getStyle(SituacaoAssistencia.AGUARDANDO_ENVIO)}
              disabled={loading}
              onClick={btnSituacaoOnClick}
              situacao="Aguardando Envio"
              className="w-100"
            >
              {constantes.AguardandoEnvio}
            </Button>
          </Col>

          <Col xs={12} md={6} lg={6} xl={2} xxl={1}  >
            <Button
              style={getStyle(SituacaoAssistencia.AGUARDANDO_RETORNO)}
              disabled={loading}
              onClick={btnSituacaoOnClick}
              situacao="Aguardando Retorno"
              className="w-100"
            >
              {constantes.AguardandoRetorno}
            </Button>
          </Col>
          <Col xs={12} md={6} lg={6} xl={2} xxl={1} >
            <Button
              style={getStyle(SituacaoAssistencia.REPROVADA)}

              disabled={loading}
              onClick={btnSituacaoOnClick}
              situacao="Reprovada"
              className="w-100"
            >
              {constantes.Reprovada}
            </Button>
          </Col>
          <Col xs={12} md={6} lg={6} xl={2} xxl={1}  >
            <Button
              style={getStyle(SituacaoAssistencia.CANCELADA)}
              className="w-100"
              disabled={loading}
              onClick={btnSituacaoOnClick}
              situacao="Cancelada"
            >
              {constantes.Cancelada}
            </Button>
          </Col>

          <Col xs={12} md={6} lg={6} xl={2} xxl={1} >
            <Button
              style={getStyle(SituacaoAssistencia.RETORNADA)}
              className="w-100"
              disabled={loading}
              onClick={btnSituacaoOnClick}
              situacao="Retornada"
            >
              {constantes.Retornada}
            </Button>
          </Col>
          <Col xs={12} md={6} lg={6} xl={2} xxl={1}  >
            <Button
              style={getStyle(SituacaoAssistencia.APROVADA)}
              className="w-100"
              disabled={loading}
              onClick={btnSituacaoOnClick}
              situacao="Aprovada"
            >
              {constantes.Aprovada}
            </Button>
          </Col>
          <Col xs={12} md={6} lg={6} xl={2} xxl={1}>
            <Button
              style={getStyle(SituacaoAssistencia.ENVIADA)}
              className="w-100"
              disabled={loading}
              onClick={btnSituacaoOnClick}
              situacao="Enviada"
            >
              {constantes.Enviada}
            </Button>
          </Col>
          <Col xs={12} md={6} lg={6} xl={2} xxl={1}  >
            <Button
              style={getStyle(SituacaoAssistencia.FINALIZADA)}
              className="w-100"
              disabled={loading}
              onClick={btnSituacaoOnClick}
              situacao="Finalizada"
            >
              {constantes.Finalizada}
            </Button>
          </Col>

          <Col xs={12} md={6} lg={6} xl={2} xxl={1}  >

          </Col>

        </Row>
      </Container>
    );
  }



  const btns = [
    {
      href: "/assistencia/",
      text: constantes.Editar,
      isVisible: (arg) => {
        return permiteEditar && arg.Situacao === "Rascunho";
      },
      onClick: (e) => {
        setIndexAssistenciaSelecionada(e.target.attributes.value.value);
      }
    },
    {
      href: "/assistencia/del",
      text: constantes.Deletar,
      variant: "danger",
      isVisible: (arg) => {
        return permiteExcluir && arg.Situacao === "Rascunho";
      },
      onClick: (e) => {
        setIndexDeletarAssistencia(e.target.attributes.value.value)
        setModalExcluirAssistencia(true)
      },
    },
    {
      href: "/assistencia/imprimir",
      text: constantes.Imprimir,
      isVisible: (arg) => {
        return arg.Situacao !== "Cancelado";
      },
      onClick: (e) => {
        setIndexImprimirAssistencia(e.target.attributes.value.value)
        setModalImprimirAssistencia(true)
      },
    },
    {
      href: "/assistencia/duplicar",
      text: constantes.Duplicar,
      isVisible: (arg) => {
        return arg.Situacao === "Rascunho";
      },
      onClick: (e) => {
        setIndexDuplicarAssistencia(e.target.attributes.value.value)

      },
    },
    {
      href: "/assistencia/",
      text: constantes.Visualizar,
      isVisible: (arg) => {
        return permiteVisualizar && arg.Situacao !== "Rascunho";
      },
      onClick: (e) => {
        setIndexAssistenciaSelecionada(e.target.attributes.value.value);
      },
    },
  ];

  function onPageSelect(number) {
    let filter = filterstate;
    filter.page = number;

    getAssistencias(filter);
  }

  const handleRowClick = (index) => {
    setSelectedRowIndex(index);
  };

  function getListView() {
    return (
      <>
        <AssistenciasList
          data={assistencias}
          pages={pages}
          records={records}
          columns={columns}
          btntype={"dropdown"}
          onPageSelect={(number) => onPageSelect(number)}
          sortname={orderField.current}
          sorttype={ordertype.current}
          onSortSelect={(item, sorttype) => {
            if (sorttype === "sort") {
              OrderChange(null, null);
            } else {
              OrderChange(item, sorttype);
            }
          }}
          striped={false}
          noselect
          buttons={btns}
          ativarSelectClientes={true}
          onRowClick={(index) => handleRowClick(index)}
          selectedRowIndex={IndexAssistenciaSelecionada}
          chipFilter={(item) => {
            const filter = filterstate && {
              ...filterstate,
              filters : item
            }
            filter && setFilterChip(item);
            filter && getAssistencias(filter);
          }}
        />
      </>
    );
  }


  return permiteVisualizar ? (
    <Container fluid className="my-3">
      <NewAlertError error={error} onClose={() => setError(null)} />

      <ModalDelete
        constantes={constantes}
        id={getIdAssistencia(IndexDeletarAssistencia)}
        open={modalExcluirAssistencia}
        close={() => {
          setModalExcluirAssistencia(false)
        }
        }
        onExcluir={(id) => {
          deletarAssistencia(id);
        }}
      >
        <Typography sx={{ fontSize: 20 }}>
          {" "}
          <BsChatRightTextFill
            style={{ marginBottom: "2px", marginRight: "8px" }}
          />
          {constantes.MsgExcluirAssistencia} {getIdAssistencia(IndexDeletarAssistencia)}
        </Typography>
      </ModalDelete>

      <ModalImprimir
        constantes={constantes}
        open={modalImprimirAssistencia}
        close={() => {
          setModalImprimirAssistencia(false)
        }
        }
      >
        <NewImprimirAssistencia idAssistencia={getIdAssistencia(IndexImprimirAssistencia)} />
      </ModalImprimir>

      <Container style={{ padding: 0, margin: 0 }} fluid className="my-3">
        {getSearchView()}
      </Container>
      <Container fluid className="my-3" style={{ padding: 0 }}>
        {getFilterSituacao()}
      </Container>
      <Container style={{ padding: 0, margin: 0 }} fluid className="my-3">
        {getListView()}
      </Container>
    </Container>
  ) : (
    <>
      <Container>
        <Alert variant="danger">
          <Alert.Heading>{constantes.Permissao}</Alert.Heading>
          <p>{constantes.Msgsempermissao}</p>
        </Alert>
      </Container>
    </>
  );
};

export default Assistencia;