
import jwt_token from "jwt-decode";
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie';

export const VERSAO = "2025-02-14-01";

export const TOKEN_KEY = "@h2jToken";
export const USER_KEY = "@h2jUser";
export const OBJECT_TOKEN_KEY = "@h2jObjectToken";
export const isAuthenticated = () => {
    const isAuth = Cookies.get(TOKEN_KEY);
    return isAuth;
}
export const getToken = () => Cookies.get(TOKEN_KEY);
export const login = (token, user) => {
    Cookies.set(TOKEN_KEY, token);
    Cookies.set(USER_KEY, user);
    Cookies.set(OBJECT_TOKEN_KEY, jwt_token(token));
};
export const refreshlogin = (token) => {
    Cookies.set(TOKEN_KEY, token);
    Cookies.set(OBJECT_TOKEN_KEY, jwt_token(token));
};
export const setobjecttoken = objecttoken => {
    Cookies.set(OBJECT_TOKEN_KEY, objecttoken);
};
export const getObjectToken = () => Cookies.get(OBJECT_TOKEN_KEY);
export const getUserLogin = () => {
    return Cookies.get(USER_KEY);
}


export const TENANT_ID = "@h2jTenantId";
export const getTenant = () => localStorage.getItem(TENANT_ID);
export const setTenatId = tenant => {
    localStorage.setItem(TENANT_ID, tenant);
};

export const CACHE_IDIOMA_SELECIONADO = "@h2jIdioma";
export const CACHE_TEXTOS_IDIOMA = "@h2jTextosIdioma";
export const GetIdiomaSelecionado = () => JSON.parse(localStorage.getItem(CACHE_IDIOMA_SELECIONADO));
export const SetIdiomaSelecionado = idioma => {
    localStorage.setItem(CACHE_IDIOMA_SELECIONADO, JSON.stringify(idioma));
};

export const GetTextosIdioma = () => JSON.parse(localStorage.getItem(CACHE_TEXTOS_IDIOMA + GetIdiomaSelecionado()));
export const SetTextosIdioma = (idioma, textosidiomas) => {
    localStorage.setItem(CACHE_TEXTOS_IDIOMA + idioma, JSON.stringify(textosidiomas));
};


export const CACHE_TIPO_FRETE = "@h2jTipoFrete";
export const GetTiposFrete = () => JSON.parse(localStorage.getItem(CACHE_TIPO_FRETE));
export const SetTiposFrete = tiposfrete => {
    localStorage.setItem(CACHE_TIPO_FRETE, JSON.stringify(tiposfrete));
};

export const CACHE_OPERACOES = "@h2jOperacoes";
export const GetOperacoes = () => JSON.parse(localStorage.getItem(CACHE_OPERACOES));
export const SetOperacoes = operacoes => {
    localStorage.setItem(CACHE_OPERACOES, JSON.stringify(operacoes));
};


export const CACHE_ATRIBUTOS = "@h2jAtributos";
export const GetAtributos = () => JSON.parse(localStorage.getItem(CACHE_ATRIBUTOS));
export const SetAtributos = atributos => {
    localStorage.setItem(CACHE_ATRIBUTOS, JSON.stringify(atributos));
};

export const CACHE_ATRIBUTOS_CLIENTES = "@h2jAtributosClientes";
export const GetAtributosClientes = () => JSON.parse(localStorage.getItem(CACHE_ATRIBUTOS_CLIENTES));
export const SetAtributosClientes = atributos => {
    localStorage.setItem(CACHE_ATRIBUTOS_CLIENTES, JSON.stringify(atributos));
};

export const CACHE_PERMISSOES = "@h2jPermissoes";
export const GetPermissoes = () => JSON.parse(localStorage.getItem(CACHE_PERMISSOES));
export const SetPermissoes = permissoes => {
    localStorage.setItem(CACHE_PERMISSOES, JSON.stringify(permissoes));
};

export const CACHE_RESPONSAVEIS = "@h2jResponsavel";
export const GetResponsaveis = () => JSON.parse(localStorage.getItem(CACHE_RESPONSAVEIS));
export const SetResponsaveis = responsaveis => {
    localStorage.setItem(CACHE_RESPONSAVEIS, JSON.stringify(responsaveis));
};

export const VERSAO_SERVER = "@h2jServerVersion";
export const GetServerVersion = () => JSON.parse(localStorage.getItem(VERSAO_SERVER));
export const SetServerVersion = serverVersion => {
    localStorage.setItem(VERSAO_SERVER, JSON.stringify(serverVersion));
};

export const GetPermissao = (segmento) => {
    const permissoes = GetPermissoes();
    return permissoes && permissoes.find((item) => { return item.Segmento === segmento });
};

export const CACHE_CONFIGURACOES = "@h2jConfiguracoes";

export const GetConfiguracoes = () => {
    const item = localStorage.getItem(CACHE_CONFIGURACOES);
    return JSON.parse(item);
};
export const SetConfiguracoes = configuracoes => {
    localStorage.setItem(CACHE_CONFIGURACOES, JSON.stringify(configuracoes));
};

export const GetPermissaoVisualizar = (segmento) => {
    const permissao = GetPermissao(segmento);
    return permissao && permissao.CanAccess;
};

export const GetPermissaoInserir = (segmento) => {
    const permissao = GetPermissao(segmento);
    return permissao && permissao.CanInsert;
};

export const GetPermissaoExcluir = (segmento) => {
    const permissao = GetPermissao(segmento);
    return permissao && permissao.CanDelete;
};

export const GetPermissaoEditar = (segmento) => {
    const permissao = GetPermissao(segmento);
    return permissao && permissao.CanUpdate;
};
export const ACESSO_SIMULADO = "@h2jAcessoSimulado";

export const getAcessoSimulado = () => {
    const token_atual = getToken();
    const token = token_atual && jwt_token(token_atual);
    return token?.IsSimulado ? JSON.parse(localStorage.getItem(ACESSO_SIMULADO)) : null
};

export const setAcessoSimulado = acessosimulado => {
    localStorage.setItem(ACESSO_SIMULADO, JSON.stringify(acessosimulado));
};

export const LimparAcessoSimulado = () => {
    localStorage.removeItem(ACESSO_SIMULADO);
};

export const FILTRO_SALVO = "@h2jFiltro";
export const getFiltro = (identificado_filtro) => {
    const filtro = localStorage.getItem(`${FILTRO_SALVO}_${identificado_filtro}`);
    return filtro ? JSON.parse(filtro) : null;
};
export const setFiltro = (identificado_filtro, filtro) => {
    localStorage.setItem(`${FILTRO_SALVO}_${identificado_filtro}`, JSON.stringify(filtro));
};
export const LimparFiltros = () => {
    localStorage.removeItem(`${FILTRO_SALVO}_ORCAMENTOS`);
};

export const getApiKeyGoogleMaps = () => {
    const configs = GetConfiguracoes();
    return configs?.ChaveApiGoogleMaps;
}

export const ClearStorageLogout = () => {
    Cookies.remove(TOKEN_KEY);
    Cookies.remove(USER_KEY);
    Cookies.remove(OBJECT_TOKEN_KEY);
    localStorage.removeItem(TENANT_ID);
    localStorage.removeItem(CACHE_TIPO_FRETE);
    localStorage.removeItem(CACHE_OPERACOES);
    localStorage.removeItem(CACHE_ATRIBUTOS);
    localStorage.removeItem(CACHE_ATRIBUTOS_CLIENTES);    
    localStorage.removeItem(CACHE_PERMISSOES);
    localStorage.removeItem(ACESSO_SIMULADO);
    localStorage.removeItem(CACHE_CONFIGURACOES);
    localStorage.removeItem(CACHE_RESPONSAVEIS);
    localStorage.setItem("@hideMenu", JSON.stringify(false));
    localStorage.removeItem(VERSAO_SERVER);

    LimparFiltros();
};
